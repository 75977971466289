import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useDeviceType from '../../hooks/use-device-type';
import { getVideoEmbedThumbUrl } from '../../services/get-video-embed-thumb-url';
import { PlayArrow } from '../icons/play-arrow';
import { PlayIcon } from '../icons/play-icon';
import styles from './post-list-item-video-thumbnail.scss';

type Oembed = {
  thumbnail_url?: string;
  url?: string;
};

const getStyle = (oembed?: Oembed) => {
  if (!oembed) {
    return undefined;
  }

  return {
    backgroundImage: oembed.thumbnail_url
      ? `url(${getVideoEmbedThumbUrl(oembed)})`
      : `url(${oembed.url})`,
  };
};

type RenderPlayArrowProps = {
  withoutLabel?: boolean;
};

const PlayArrowComp = ({ withoutLabel }: RenderPlayArrowProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  return isMobile && !withoutLabel ? (
    <div className={styles.button}>
      <PlayArrow className={styles.arrow} /> {t('video-embed.load-video')}
    </div>
  ) : (
    <span>
      <PlayIcon className={styles.icon} />
    </span>
  );
};

type Props = {
  oembed?: Oembed;
  withoutLabel?: boolean;
};

export const PostListItemVideoThumbnail: React.FC<Props> = ({ oembed, withoutLabel }) => {
  return (
    <div className={styles.container}>
      <div className={styles.thumbnail} style={getStyle(oembed)} role="img">
        <div className={styles.overlay} />
        <PlayArrowComp withoutLabel={withoutLabel} />
      </div>
    </div>
  );
};

export default PostListItemVideoThumbnail;
